<template>
    <div>
        <a-spin v-if="loading" :spinning="loading"></a-spin>
        <div v-else>
            <a-select v-model="memberPermissions" class="select" mode="multiple" size="large" placeholder="暂无任何权限，点击选择权限">
                <template v-for="(name,key) in permissionsList">
                    <a-select-option :key="key" :value="key">{{ name }}</a-select-option>
                </template>
            </a-select>
        </div>
        <button @click="savePermissions" class="btn">保存</button>
    </div>
</template>

<script>
import api from "@/repo/api";

export default {
    name: "Permission",
    props: {
        memberId: String
    },
    data() {
        return {
            loading: true,
            permissionsList: [],
            memberPermissions: []
        }
    },
    mounted() {
        this.getPermissions()
    },
    methods: {
        getPermissions() {
            api.get('/ast-member/permissions', {
                member_id: this.memberId
            }, (res) => {
                this.loading = false
                this.permissionsList = res.data.permissions
                this.memberPermissions = res.data.member_permissions || []
            })
        },
        savePermissions() {

            this.$loading.show()

            api.post('/ast-member/save-permissions', {
                member_id: this.memberId,
                permissions: this.memberPermissions,
            }, (res) => {
                this.$loading.hide()
                this.$message.data(res)
            })
        },
    }
}
</script>

<style scoped lang="less">
.select {
    width: 800px;
    margin-bottom: 32px;
}
</style>