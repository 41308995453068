<template>
    <div>
        <div class="loading-block" v-if="loading">
            <loader/>
        </div>
        <div class="content" v-if="memberInfo.id">
            <div class="detail-header">
                <div class=" member-info ">
                    <img :src="memberInfo.avatar" alt="">
                    <div>
                        <div class="member-name">
                            <span class="name-str">{{ memberInfo.mark_name || memberInfo.name }}</span>
                            <div v-if="memberInfo.level_id" class="level-card">{{ memberInfo.level_name }}</div>
                            <div v-else class="level-card yk">游客</div>
                        </div>
                        <div class="member-attrs">
                            <div class="attr-name">ID</div>
                            <div class="attr-value">{{ memberInfo.member_id }}</div>
                            <div class="attr-name">手机号</div>
                            <div class="attr-value">{{ memberInfo.phone || '-' }}</div>
                            <div class="attr-name">创建时间</div>
                            <div class="attr-value">{{ $moment(memberInfo.created_at).format('yyyy-MM-DD') }}</div>
                            <div class="attr-name mc-qrcode">

                                <a-popover title="扫码进入用户个人中心">
                                    <template slot="content">
                                        <img width="200px" :src="qrcodeUrl" alt="">
                                    </template>
                                    <span>小程序码</span>
                                    <a-icon type="qrcode"/>
                                </a-popover>


                            </div>
                        </div>
                    </div>
                </div>
                <div class="parent-btn" @click="showMemberParentModal = true">
                    <span>推荐关系图</span>
                </div>
            </div>
            <a-tabs class="tabs" :default-active-key="1" @change="tabsChanged">
                <a-tab-pane :key="1" tab="本人消费"></a-tab-pane>
                <a-tab-pane :key="2" tab="分账奖励"></a-tab-pane>
                <a-tab-pane :key="3" tab="记账奖励"></a-tab-pane>
                <a-tab-pane :key="4" tab="账号权限"></a-tab-pane>
                <a-tab-pane :key="5" tab="优惠使用"></a-tab-pane>
            </a-tabs>

            <div v-if="tabActivedKey === 1">
                <detail-order :member-id="memberId"/>
            </div>
            <div v-else-if="tabActivedKey === 2">
                <detail-share key="ds2" :member-id="memberId" type="SHARING"></detail-share>
            </div>
            <div v-else-if="tabActivedKey === 3">
                <detail-share key="ds3" :member-id="memberId" type="OFFLINE"></detail-share>
            </div>
            <div v-else-if="tabActivedKey === 4">
                <permission :member-id="memberId"></permission>
            </div>
            <div v-else-if="tabActivedKey === 5">
                <detail-coupon :member-id="memberId"></detail-coupon>
            </div>


            <a-modal width="986px" v-model="showMemberParentModal" :footer="null">

                <Recommend
                        :member="memberInfo"
                        :ast-parents="memberInfo.ast_parents"
                        :yc-parents="memberInfo.yc_parents"
                />

            </a-modal>

        </div>
    </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import api from "@/repo/api";
import Recommend from "@/views/AST/Cashier/Com/Recommend";
import DetailOrder from "@/views/AST/Member/Com/DetailOrder.vue";
import DetailShare from "@/views/AST/Member/Com/DetailShare.vue";
import Permission from "@/views/AST/Member/Com/Permission.vue";
import DetailCoupon from "@/views/AST/Member/Com/DetailCoupon.vue";

export default {
    name: "Detail",
    components: {DetailCoupon, Permission, DetailShare, DetailOrder, Recommend, Loader},
    data() {
        return {
            routeMatch: '/ast/members',
            memberId: null,
            memberInfo: {},
            loading: false,
            showMemberParentModal: false,
            tabActivedKey: 1,
            qrcodeUrl: "",
        }
    },
    watch: {
        '$route.params.id'() {
            this.loadInfo()
        }
    },
    mounted() {
        this.loadInfo()
    },
    methods: {
        loadInfo() {
            this.memberId = this.$route.params.id

            this.memberInfo = {}

            this.getDetail()
        },
        getDetail() {
            this.loading = true

            api.get('/ast-member/detail', {id: this.memberId}, (data) => {

                this.loading = false

                if (data.code !== 0) {
                    return this.$message.error(data.msg);
                }

                this.memberInfo = data.data

                this.qrcodeUrl = this.$qrcode(
                    '/packagesB/userDetail/userDetail?id=' + this.memberId,
                    `会员详情${this.memberId}`
                );

                this.loading = false

            })
        },
        tabsChanged(key) {
            this.tabActivedKey = key
        }
    }
}
</script>

<style scoped lang="less">

.detail-header, .member-info, .member-attrs {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-content: center;
    align-items: center;
}

.detail-header {
    justify-content: space-between;
    align-items: flex-end;
}

.member-info {
    img {
        width: 56px;
        height: 56px;
        margin-right: 16px;
        border-radius: 50%;
    }
    
    .member-name {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
    }
    
    .member-attrs {
        line-height: 22px;
        margin-top: 8px;
    }
    
    .attr-name {
        font-weight: 400;
        color: #999999;
        font-size: 16px;
        margin-right: 8px;
    }
    
    .attr-value {
        font-weight: 400;
        color: #333333;
        font-size: 16px;
        margin-right: 48px;
    }
}

.parent-btn {
    cursor: pointer;
    padding-right: 19px;
    position: relative;

    &:after {
        content: ' ';
        display: block;
        width: 12px;
        height: 12px;
        background-image: url("/assets/icons/pay-back.png");
        background-size: cover;
        position: absolute;
        right: 0;
        top: 5px;
    }
}

.level-card {
    border-radius: 8px;
    border: 1px solid #EDC483;
    color: #EDC483;
    text-align: center;
    display: inline-block;
    padding: 2px 6px;
    font-size: 0.8em;
    
    &.yk {
        border-color: transparent;
        color: #333333;
    }
}

.name-str {
    margin-right: 18px;
}

.tabs {
    margin-top: 40px;

    /deep/ .ant-tabs-tab {
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
        
        &.ant-tabs-tab-active {
            color: #333333;
        }
    }
    
    /deep/ .ant-tabs-ink-bar {
        background-color: #333333;;
    }
}

.mc-qrcode {
    cursor: pointer;
}

</style>