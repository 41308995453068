<template>
    <div class="self-info">
        <div class=" member-card">
            <img :src="member.avatar" alt="">
            <div class="member-name">{{ member.remark || member.mark_name || member.name }}</div>

            <div v-if=" member.level_name" class="level-name">
                {{ member.level_name }}
            </div>
            <div v-else-if="!out" class="level-name guest">游客</div>

            <div class="member-id">ID {{ member.member_id }}</div>

            <div v-if="member.is_master" class="member-tag">主理人</div>
            <div v-else-if="self" class="member-tag">本人</div>
            <div v-else-if="out" class="member-tag out">外部</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'member-card',
    props: {
        yc: Object,
        ast: Object,
        all: Object,
        self: Boolean
    },
    data() {
        return {
            out: false,
            member: {}
        }
    },
    watch: {
        yc() {
            this.loadMemberInfo()
        },
        ast() {
            this.loadMemberInfo()
        },
        all() {
            this.loadMemberInfo()
        }
    },
    mounted() {
        this.loadMemberInfo()
    },
    methods: {
        loadMemberInfo() {
            let ast = this.ast || this.all['ast']

            if (ast) {
                return this.member = ast
            }

            let yc = this.yc || this.all['yc']

            if (yc) {

                this.member = {
                    name: yc.nickname || yc.name,
                    member_id: yc.id,
                    avatar: yc.avatarUrl || yc.avatar
                }

                this.out = !this.self
            }
        }
    }
}
</script>

<style scoped lang="less">

.member-card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    color: #333333;

    img {
        width: 48px;
        height: 48px;
    }

    .member-name {
        margin-top: 10px;
    }

    .level-name {
        margin-top: 6px;
        color: #FBB03B;

        &.guest {
            color: #333333;
        }
    }

    .member-id {
        margin-top: 6px;
    }

    img {
        border-radius: 50%;
    }

    .member-name {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
    }
}

.member-tag {
    position: absolute;
    top: 40px;
    background: rgba(0, 0, 0, 0.1);
    width: 40px;
    height: 16px;
    backdrop-filter: blur(10px);
    border-radius: 8px;
    line-height: 16px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;

    &.out {
        background-color: #ff4d4f;
    }
}
</style>