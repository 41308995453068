<template>
    <div>
        <div>
            <a-table
                    class="stock-table"
                    :data-source="table.data"
                    :loading="table.loading"
                    :pagination="table.pagination"
                    :rowKey="record => record.id || record.key"
                    @change="tableChanged"
                    bordered
            >
                <a-table-column
                        title="优惠名称"
                        data-index="title"
                        align="center"
                >
                    <div slot-scope="title">

                        <a-tooltip>
                            <template slot="title">
                                <div style="width: 150px">{{ title }}</div>
                            </template>
                            {{ title.substring(0, 8) }}
                        </a-tooltip>

                    </div>

                </a-table-column>

                <a-table-column
                        align="center"
                        title="领取时间"
                        data-index="created_at"
                >
                    <template slot-scope="time">
                        {{ $moment(time).format('yyyy-MM-DD HH:mm') }}
                    </template>

                </a-table-column>


                <a-table-column title="优惠金额" data-index="deduct_amount" align="center">
                    <template slot-scope="deduct_amount">
                        ￥{{ deduct_amount }}
                    </template>
                </a-table-column>

                <a-table-column title="状态" data-index="status" align="center">
                    <template slot-scope="money,item">
                        <template v-if="item.used_at">已使用</template>
                        <template v-else-if="isExp(item.expired_at)">
                            已过期
                        </template>
                        <template v-else> -</template>
                    </template>
                </a-table-column>

                <a-table-column title="操作" data-index="op" align="center">
                    <template slot-scope="money,item">
                        <template v-if="(!item.used_at) && isExp(item.expired_at)">
                            <small @click="deleteCoupon(item.id)"><a>删除</a></small>
                        </template>
                        <template v-else> - </template>
                    </template>
                </a-table-column>


            </a-table>
        </div>
    </div>
</template>

<script>
import api from "@/repo/api";

export default {
    name: "DetailCoupon",
    props: {
        memberId: String
    },
    data() {
        return {
            table: {
                columns: [],
                loading: false,
                data: [],
                pagination: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 20
                },
            },
            query: {},
        }
    },
    mounted() {
        this.getFirstList()
    },
    methods: {
        isExp(exp) {
            let time = this.$moment(exp)

            return time.valueOf() <= (new Date).getTime()
        },
        getFirstList() {
            this.query.page = 1
            this.getList()
        },
        deleteCoupon(id) {
            if (!confirm('确定要删除这张优惠券吗？')) return;

            this.$loading.show()

            api.post('/ast-cashier/delete-coupon', {
                id
            }, (res) => {

                if (res.code === 0) this.getList()

                this.$loading.hide()
                this.$message.data(res)
            })
        },
        getList() {

            if (!this.memberId) return;

            this.table.loading = true

            this.query.member_id = this.memberId

            this.query.type = this.type

            api.get('/ast-cashier/member-coupons', this.query, (data) => {

                this.table.data = data.data.data
                this.table.pagination.current = data.data.current_page
                this.table.pagination.total = data.data.total
                this.table.pagination.pageSize = data.data.per_page

                this.table.loading = false
            })
        },
        tableChanged(pagination) {
            this.query.page = pagination.current
            this.getList()
        },
    }
}
</script>

<style scoped lang="less">

</style>